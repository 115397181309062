import { defineStore } from 'pinia'

export const useStoriesStore = defineStore('stories', {
  state: () => { 
    return {
      headers: [
        {title: "ID", key: "id", width: "50px", display: true, sortable: true},
        {title: "Expediteur", key: "from", width: "200px", display: true, sortable: true},
        {title: "Email Expediteur", key: "email", width: "200px", display: true, sortable: false},
        {title: "Sujet", key: "subject", width: "200px", display: true, sortable: false},
        {title: "message", key: "content", display: true, sortable: false, width: "300px"},
        {title: "langage", key: "language", display: true, sortable: false, width: "100px" },
        {title: "Envoyé à/le ", key: "created_at", align: 'center', width:"200px", display: true, sortable: true},
        {title: "New", key: "new", display: true, width: "80px", sortable: true },
        {title: "Repondu", key: "answered", display: true, width: "80px", sortable: true },
        {title: "Actions", key: "actions", display: true, sortable: false, width: "100px",}
        ],
      params:{
        filters: {} ,
        itemsPerPage: 25,
        search: '',
        sortBy:[]
      },
      total: 0, 
      items: [],
      folders: [],
      root_folders: [],
      tree: [],
      loading: false
    }
  },
  actions: {
    async fetchCollection(update) {
      const urlParams = { ...this.params, ...update };
      const { data } = await useAPIFetch("/stories.json", { params: urlParams })
      if (data.value) {
        this.items = data.value.data;
        this.folders = data.value.meta.folders;
        this.root_folders = data.value.meta.root_folders;
        this.tree = data.value.tree;
        this.total = data.value.recordsTotal ;
        this.loading = false
      }
    },
    async getStory(id) {
      const { data } = await useAPIFetch(`/stories/${id}.json`)
      return data.value 
    },
    async helpStories() {
      const { data } = await useAPIFetch(`/stories/help.json`)
      return data.value 
    }


  

  },
})